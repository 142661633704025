.export-to-excel-button {
  outline: 0;
  border: 0;
  margin: 0;
  cursor: pointer;
  text-decoration: none;
  font-family: Rubik;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.75;
  min-width: 64px;
  padding: 6px 16px;
  border-radius: 4px;
  color: #fff;
  background-color: #0b2a4a;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
    0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  text-transform: none;
}
